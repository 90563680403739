// login.js

import config from './config.js';

const generateRandomString = (length) => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const sha256 = async (plain) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest('SHA-256', data);
};

const base64urlencode = (a) => {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(a)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
};

const pkceChallengeFromVerifier = async (v) => {
  const hashed = await sha256(v);
  return base64urlencode(hashed);
};

// Função para iniciar o login direto com Autodesk
const initiateAutodeskLogin = async () => {
  const codeVerifier = generateRandomString(128);
  localStorage.setItem('pkce_code_verifier', codeVerifier);
  const codeChallenge = await pkceChallengeFromVerifier(codeVerifier);
  const state = generateRandomString(16);

  const response = await fetch(
    `${config.apiUrl}/oauth2/auth/url?code_challenge=${encodeURIComponent(
      codeChallenge
    )}&state=${encodeURIComponent(state)}`
  );
  const authUrl = await response.text();
  console.log('Redirect URL:', authUrl);
  window.location.href = authUrl;
};

// Torna a função acessível no escopo global
window.initiateAutodeskLogin = initiateAutodeskLogin;
